import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UploadComponent } from './upload.component';

@NgModule({
  imports: [CommonModule],
  exports: [UploadComponent],
  declarations: [UploadComponent],
  providers: [],
})
export class UploadModule {}
